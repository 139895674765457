import {
  RequestMethod,
  RequestName,
  RequestProxy,
  useCaller,
} from '~/server/bridges';
import type { FormPayload } from '~/units/forms/types';

export class FormsService {
  send(uniqueId: string, payload: FormPayload) {
    return useCaller(
      RequestProxy.AKYA_AXON,
      RequestName.SEND_FORM,
      `form/${uniqueId}`,
      RequestMethod.POST,
      {},
      payload
    );
  }
}

<template>
  <client-only>
    <VDatePicker
      v-model="childModelValue"
      :mode="mode"
      :masks="dateMasks"
      :name="name"
      is24hr
      :is-required="isRequired"
      :select-attribute="selectAttribute"
    >
      <template #default="{ inputValue, inputEvents }">
        <slot :inputValue="inputValue" :inputEvents="inputEvents">
          <nc-input
            :label="`${label}${isRequired ? '*' : ''}`"
            :name="name"
            :value="inputValue"
            :input-events="inputEvents"
            :is-custom-value-binding="true"
          />
        </slot>
      </template>
    </VDatePicker>
  </client-only>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import NcInput from '~/components/common/inputs/NcInput.vue';

export default defineNuxtComponent({
  name: 'LibraryDatePicker',
  components: { NcInput },

  props: {
    modelValue: {
      required: true,
    },

    dateMasks: {
      required: false,
      type: Object,
      default: () => {
        return {
          input: 'DD/MM/YYYY',
        };
      },
    },

    name: {
      required: false,
      type: String,
      default: '',
    },

    label: {
      required: false,
      type: String,
      default: '',
    },

    isRequired: {
      required: false,
      type: Boolean,
      default: false,
    },

    mode: {
      required: false,
      type: String as PropType<'date'>,
      default: 'date',
    },
  },

  computed: {
    childModelValue: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit('update:modelValue', value);
      },
    },

    selectAttribute() {
      return {
        highlight: {
          style: {
            // Circle styles
            background: 'blue',
          },
          contentStyle: {
            // Text styles
            color: 'white',
          },
        },
      }
    }
  },
});
</script>
